@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* General PostBox Styles */
.postbox {
    position: fixed;
    top: 90px; /* Header height */
    left: 350px; /* Sidebar width */
    width: 650px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.postbox__input-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.postbox__avatar {
    width: 40px;
    height: 40px;
    color: #666;
}

.postbox__input {
    flex: 1;
    padding: 12px;
    border: 1px solid #eee;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #f0f2f5;
    color: #65676b;
}

.postbox__input::placeholder {
    color: #65676b;
}

.postbox__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Higher than any other z-index */
}

.postbox__modal {
    background: white;
    width: 400px;
    max-width: 90vw;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    z-index: 10000; /* Higher than overlay */
    margin-top: -5vh; /* Slight offset from center */
}

.postbox__modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
}

.postbox__modal-header h2 {
    font-size: 18px;
    color: #1a1a1a;
    margin: 0;
}

.postbox__modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.postbox__tag-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.postbox__tag-button {
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    background-color: #f0f2f5;
    cursor: pointer;
    font-size: 15px;
    text-align: left;
    transition: all 0.2s ease;
    color: #1a1a1a;
}

.postbox__tag-button:hover {
    background-color: #e4e6eb;
    transform: translateY(-1px);
}

.postbox__selected-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    background-color: #f0f2f5;
    border-radius: 8px;
    font-size: 14px;
    color: #1a1a1a;
}

.postbox__change-tag {
    border: none;
    background: none;
    color: #3F98FF;
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.postbox__change-tag:hover {
    background-color: rgba(63, 152, 255, 0.1);
}

.postbox__textarea {
    width: 100%;
    min-height: 150px;
    padding: 15px;
    border: none;
    border-radius: 8px;
    background-color: #f0f2f5;
    font-size: 16px;
    resize: none;
    color: #1a1a1a;
}

.postbox__textarea::placeholder {
    color: #65676b;
}

.postbox__modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid #eee;
}

.postbox__post-button {
    padding: 8px 24px;
    border: none;
    border-radius: 6px;
    background-color: #31C65B;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.postbox__post-button:hover {
    background-color: #2bb152;
}

.postbox__post-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.postbox__cancel-button {
    padding: 8px 24px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: white;
    color: #333;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
}

.postbox__cancel-button:hover {
    background-color: #f0f2f5;
    border-color: #999;
}

.postbox__userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    justify-content: center;
}

.postbox__username {
    font-weight: 600;
    font-size: 16px;
    color: #333;
    margin-bottom: 2px;
}

.postbox__profession {
    font-size: 14px;
    color: #666;
}

/* Buttons */
.postbox__buttons {
  margin-top: 0 !important;
  padding-top: 0 !important;
  display: flex !important;
  gap: 15px !important;
  align-items: center !important;
  margin-left: 12% !important;
}

.postbox__buttons button {
  display: flex;
  align-items: center;
  gap: 5px !important;
  border: none;
  background: none;
  cursor: pointer;
  color: #3F98FF;
  font-size: 14px !important;
  border-radius: 4px;
  transition: background-color 0.2s;
  border-bottom: 2px solid transparent;
}

.postbox__buttons button:hover {
  background-color: #f0f2f5;
  border-bottom: 2px solid #3F98FF;
}

/* Popup Styles */
.postbox__popup {
    display: none; /* Remove duplicate overlays */
}

.postbox__popup {
    background: white;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.postbox__popupHeader {
    display: flex;
    align-items: center;
    gap: 15px;
}

.postbox__popupTextarea {
  flex: 1;
  border: none;
  border-radius: 10px;
  padding: 10px;
  resize: none;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: black;
  height: 300px !important;
  overflow-y: auto;
  margin: 0 10px;
}

.postbox__popupTextarea:focus {
  outline: none;
}

/* Popup Extra Buttons */
.postbox__popupExtraButtons {
  display: flex;
  gap: 15px;
  margin: 15px 0 !important;
  border-bottom: 1px solid #bbb;
  padding-bottom: 10px;
}

.postbox__popupExtraButtons button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: #3F98FF;
  font-size: 16px;
  font-weight: 500 !important;
  padding: 0 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
  font-family: 'Inter', sans-serif;
}

.postbox__popupExtraButtons button:hover {
  background-color: #f0f2f5;
}

/* Popup Buttons */
.postbox__popupButtons {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
  padding: 0 20px !important;
}

.postbox__popupCancelButton {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-left: 280px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.3s, border-color 0.3s;
}

.postbox__popupCancelButton:hover {
  background-color: #e0e0e0;
  border-color: #bbb;
}

.postbox__popupPostButton {
  background-color: #31C65B;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s ease;
}

.postbox__popupPostButton:hover:not(:disabled) {
  background-color: #2aa64d;
}

.postbox__popupPostButton.disabled,
.postbox__popupPostButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Action Tags Popup Styles */
.actionTags__popup {
    display: none; /* Remove duplicate overlays */
}

.actionTags__popupContent {
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 400px !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px; 
}

.actionTags__header {
  display: flex;
  align-items: center;
}

.actionTags__backButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #3F98FF;
  cursor: pointer;
  font-size: 16px;
}

.actionTags__backButton:hover {
  text-decoration: underline;
}

.action-tag-header {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  margin-left: 15%;
}

.action-tag-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action-tag-option {
  margin: 10px 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: transform 0.2s;
}

.action-tag-option:hover {
  transform: scale(1.05);
}

.action-tag-label {
  margin-left: 5px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
}

/* Style for radio buttons */
input[type="radio"] {
  margin: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background: white;
  border: 2px solid #ddd;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: relative; /* Ensure positioning for the checked state */
}

input[type="radio"]:checked {
  border-color: #31C65B;
  background: #31C65B;
}

input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  display: none; /* Hide the inner circle by default */
}

input[type="radio"]:hover {
  border-color: #2aa64d;
}

/* Show the inner circle only when checked */
input[type="radio"]:checked:hover::after {
  display: block; /* Show the inner circle on hover */
}

.postbox__modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.postbox__modal-description {
    color: #65676b;
    font-size: 14px;
    margin: 0 0 15px 0;
}

/* Post Creation Modal */
.postbox__modal.post-creation {
    width: 500px;
    max-width: 95vw;
}
