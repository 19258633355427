@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Tailwind CSS Setup */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Resets */
* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;  /* Light gray background */
}

/* Layout for pages with sidebar */
.app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  margin-left: 350px;  /* Ensure this matches the sidebar width */
  margin-top: 90px;   /* Height of header */
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;  /* Ensure relative positioning */
}

/* Add this new class for PostBox positioning */
.post-box-container {
  width: var(--post-box-width);  /* Using your existing variable */
  
}

/* Global Variables */
:root {
  --theChange-color: #31C65B;
  --header-height: 90px;
  --sidebar-width: 350px;
  --post-box-width: 650px;
  
  /* Z-index hierarchy */
  --z-negative: -1;
  --z-zero: 0;
  --z-low: 1;
  --z-header: 1000;
  --z-sidebar: 900;
  --z-dropdown: 1100;
  --z-tooltip: 1200;
  --z-modal-backdrop: 1300;
  --z-modal: 1400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Animation Delays */
.delay-300 {
  animation-delay: 300ms;
}
.delay-700 {
  animation-delay: 700ms;
}
.delay-1000 {
  animation-delay: 1000ms;
}

/* Tailwind Customizations */
@layer base {
  body {
    @apply antialiased text-gray-100;
  }
}
@layer components {
  .auth-button {
    @apply w-full py-3 px-4 bg-gradient-to-r from-green-400 to-emerald-500 text-white rounded-lg font-semibold hover:opacity-90 transition duration-200 disabled:opacity-50;
  }
  .auth-link {
    @apply text-indigo-400 hover:text-indigo-300 transition duration-200;
  }
  .auth-container {
    @apply p-8 rounded-2xl w-full max-w-md space-y-6;
  }
  .auth-title {
    @apply text-2xl font-bold text-gray-100 text-center mb-6;
  }
  .input-field {
    @apply w-full py-2 px-4 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-400 focus:ring-2 focus:ring-green-400 text-white placeholder-gray-400 transition duration-200 outline-none;
  }
  .responsive-text {
    @apply text-sm sm:text-base;
  }
}

.test-tailwind {
  @apply bg-red-500;
}

/* Fixed header */
.header {
  z-index: var(--z-header);
}

/* Sidebar */
.sidebar {
  z-index: var(--z-sidebar);
}

/* Modal/Dialog styles */
.modal-backdrop {
  position: fixed;
  inset: 0; /* shorthand for top: 0; right: 0; bottom: 0; left: 0; */
  min-height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal-backdrop);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  z-index: var(--z-modal);
}

/* Ensure dropdowns appear above regular content */
.dropdown {
  z-index: var(--z-dropdown);
}

/* Tooltips should appear above dropdowns */
.tooltip {
  z-index: var(--z-tooltip);
}