@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');


.search-results-container {
    max-width: 1200px;
    margin: 90px auto 0;
    padding: 2rem;
    margin-left: 305px;
    width: calc(100% - 305px);
}

.content-section {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-top: 20px;
}

.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.search-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
}

.sort-select {
    padding: 0.5rem 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    background: white;
    color: #4a4a4a;
    cursor: pointer;
    transition: all 0.2s ease;
}

.sort-select:hover {
    border-color: #31C65B;
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.user-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.3s ease;
}

.user-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
}

.user-card-content {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.user-avatar {
    width: 64px;
    height: 64px;
    min-width: 64px;
    border-radius: 50%;
    overflow: hidden;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-icon {
    width: 40px;
    height: 40px;
    color: #9e9e9e;
}

.user-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.user-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
}

.user-profession,
.user-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.follow-button {
    padding: 0.5rem 1.5rem;
    background: #31C65B;
    color: white;
    border: none;
    border-radius: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    align-self: flex-end;
}

.follow-button:hover {
    background: #2ba04d;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    gap: 1rem;
}

.loader-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #31C65B;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.no-results {
    text-align: center;
    padding: 3rem;
    color: #666;
    font-size: 1.125rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .search-results-container {
        margin-left: 0;
        width: 100%;
        padding: 1rem;
    }

    .results-grid {
        grid-template-columns: 1fr;
    }

    .search-header {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
}