body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.profile2-container {
  max-width: 1015px !important;
  width: calc(100% - 400px) !important;
  margin-left: 375px !important;
  margin-top: 90px !important;
  margin-right: 25px !important;
  border-radius: 16px !important;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  position: relative;
  height: calc(100vh - 125px) !important;
  overflow-y: auto !important;
}

.loading2-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: calc(100vh - 90px) !important;
  margin-left: 375px !important;
  gap: 20px !important;
}

.loading2-container p {
  color: #666 !important;
  font-size: 18px !important;
}

.error2-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: calc(100vh - 90px) !important;
  margin-left: 375px !important;
  text-align: center !important;
}

.cover2-image {
  width: 100% !important;
  height: 200px !important;
  border-radius: 16px 16px 0 0 !important;
  background: linear-gradient(135deg, #31C65B, #2196F3) !important;
}

.profile2-content {
  padding: 0 40px 30px !important;
}

.profile2-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top: -50px !important;
  margin-bottom: 30px !important;
}

.profile2-header-left {
  display: flex !important;
  align-items: center !important;
  gap: 24px !important;
}

.user2-avatar {
  width: 160px !important;
  height: 160px !important;
  border-radius: 50% !important;
  border: 6px solid white !important;
  background-color: #f0f0f0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  flex-shrink: 0 !important;
}

.user2-avatar-icon {
  color: #666 !important;
}

.user2-info {
  padding-top: 60px !important; /* Align with avatar bottom */
}

.user2-name {
  font-size: 32px !important;
  font-weight: 700 !important;
  margin-bottom: 16px !important;
  color: #1a1a1a !important;
}

.user2-details {
  display: flex !important;
  flex-direction: column !important;
  gap: 12px !important;
}

.detail2-item {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  color: #666 !important;
}

.detail2-text {
  font-size: 18px !important;
  color: #4a4a4a !important;
}

.add2-friend-button {
  background: linear-gradient(135deg, #31C65B, #2196F3) !important;
  color: white !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  transition: transform 0.2s, box-shadow 0.2s !important;
  white-space: nowrap !important;
  height: fit-content !important;
  margin-top: 60px !important; /* Align with user info */
}

.add2-friend-button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 15px rgba(49, 198, 91, 0.3) !important;
}

.stats2-section {
  width: 100% !important;
  display: flex !important;
  gap: 24px !important;
  margin-top: 24px !important;
}

.stat2-box {
  flex: 1 !important;
  background: white !important;
  padding: 24px !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
  text-align: center !important;
  transition: transform 0.2s !important;
}

.stat2-box:hover {
  transform: translateY(-2px) !important;
}

.stat2-number {
  display: block !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #31C65B !important;
  margin-bottom: 8px !important;
}

.stat2-label {
  font-size: 16px !important;
  color: #666 !important;
}

.vision2-section,
.skills2-section {
  padding: 30px !important;
  background: white !important;
  margin-top: 24px !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
}

.vision2-title,
.skills2-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #1a1a1a !important;
  margin-bottom: 16px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.vision2-text {
  font-size: 16px !important;
  line-height: 1.6 !important;
  color: #666 !important;
}

.skills2-list {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 12px !important;
}

.skill2-tag {
  background: linear-gradient(135deg, #31C65B20, #2196F320) !important;
  color: #1a1a1a !important;
  padding: 8px 16px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.popup2-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
}

.popup2-card {
  width: 100% !important;
  max-width: 400px !important;
  border-radius: 16px !important;
}

.popup2-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 20px !important;
  padding: 20px !important;
  text-align: center !important;
}

.popup2-buttons {
  display: flex !important;
  gap: 16px !important;
  margin-top: 20px !important;
}