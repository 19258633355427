.stats-section {
    display: flex !important;
    justify-content: center !important;
    gap: 60px !important;
    padding: 24px !important;
    background: white !important;
    border-radius: 16px !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
    margin: 24px 0 !important;
    max-width: 900px;
    align-self: center;
}

.stat-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    min-width: 100px !important;
    padding: 16px !important;
    border-radius: 12px !important;
    transition: transform 0.2s !important;
    cursor: pointer;
}

.stat-item:hover {
    transform: translateY(-2px) !important;
    background: rgba(49, 198, 91, 0.05) !important;
}

.stat-number {
    font-size: 28px !important;
    font-weight: 700 !important;
    color: #31C65B !important;
    margin-bottom: 8px !important;
}

.stat-label {
    font-size: 16px !important;
    color: #666 !important;
    font-weight: 500 !important;
}

.with-tooltip {
    position: relative !important;
}

.tooltip-text {
    position: absolute !important;
    bottom: 100% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
    padding: 8px 12px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    white-space: nowrap !important;
    opacity: 0 !important;
    pointer-events: none !important;
    transition: opacity 0.2s !important;
}

.with-tooltip:hover .tooltip-text {
    opacity: 1 !important;
}

.skills-display {
    background: white !important;
    border-radius: 16px !important;
    padding: 20px !important;
    margin-top: -12px !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
}

.skills-grid {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 8px !important;
    justify-content: center !important;
}

.skill-badge {
    background: #e8f5e9 !important;
    color: #2e7d32 !important;
    padding: 6px 12px !important;
    border-radius: 16px !important;
    font-size: 14px !important;
} 