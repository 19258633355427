:root {
    --z-index-base: 1;
    --z-index-buttons: 10;
    --z-index-dropdown: 100;
    --z-index-modal-overlay: 1000;
    --z-index-modal: 1001;
}

.comment-container {
  margin-bottom: 20px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.comment-user {
  display: flex;
  align-items: flex-start;
  gap: 5px !important;
  margin-top: 0 !important;
}

.comment-user svg {
  width: 35px;
  height: 35px;
  min-width: 35px;
  margin-right: 0;
  margin-top: 10px !important;
  color: #666;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
  gap: 0 !important;
}


  .comment-profession {
    margin: 0 !important;
    color: #666;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
  }

.comment-timestamp {
  color: #666;
  font-size: 14px;
}

.comment-options {
  position: relative;
}

.options-toggle {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.options-toggle:hover {
  background-color: rgba(49, 198, 91, 0.1);
  color: #31C65B;
}

.options-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  z-index: 10;
  min-width: 120px;
  margin-top: 4px;
}

.options-menu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  text-align: left;
}

.options-menu button:hover {
  background-color: rgba(49, 198, 91, 0.1);
  color: #31C65B;
}

.comment-content {
  margin: 6px 0 8px;
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.comment-content p {
  margin: 0;
  margin-left: 45px;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
}

.comment-edit-input {
  width: 100%;
  min-height: 60px;
  max-height: 100px !important;
  padding: 12px !important;
  margin-bottom: 10px !important;
  border: 1px solid #31C65B !important;
  border-radius: 8px;
  font-size: 14px;
  color: #000 !important;
  line-height: 1.5;
  resize: vertical;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  transition: border-color 0.2s ease;
  background-color: white;
  font-family: inherit;
}

.comment-edit-input:focus {
  outline: none;
  border-color: #28a745 !important;
}

.comment-actions {
  display: flex;
  align-items: center;
  margin-left: 45px;
  gap: 12px;
  padding: 8px 0 0;
  margin-top: 5px;
  border-top: 1px solid #e0e0e0;
}

.comment-like-btn,
.comment-reply-btn,
.comment-share-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s ease;
}

.comment-like-btn.liked {
  color: #31C65B;
}

.comment-like-btn:hover,
.comment-reply-btn:hover,
.comment-share-btn:hover {
  color: #31C65B;
}
.comment-reply-btn.active{
    color: #31C65B;
}

.reply-input-container {
  margin: 10px 0;
  padding: 0 15px;
}


.post-reply-btn:hover {
  background-color: #28a745;
}

.post-reply-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: var(--z-index-modal);
  width: 300px;
  text-align: center;
  pointer-events: all;
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-modal-overlay);
  pointer-events: all;
}

.delete-modal p {
  margin: 0 0 20px;
  color: #333;
  font-size: 16px;
  background: white;
}

.delete-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background: white;
}

.delete-modal-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.delete-modal-buttons button:first-child {
  background-color: #f0f0f0;
  color: #333;
}

.delete-modal-buttons button:first-child:hover {
  background-color: #e0e0e0;
}

.delete-modal-buttons .delete-confirm {
  background-color: #dc3545;
  color: white;
}

.delete-modal-buttons .delete-confirm:hover {
  background-color: #c82333;
}

.replies-section {
  margin: 0 15px;
  padding-left: 20px;
  border-left: 2px solid #e0e0e0;
}

.comment-input-container {
  margin: 0 15px 20px !important;
}

.comment-input-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.comment-button {
  position: static;
  background-color: #31C65B;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  width: auto;
  height: auto;
  margin-left: auto !important;
  margin-right: 11px !important;
  margin-top: 5px !important;
  display: block;
  margin-bottom: 12px;
}

.comment-button:hover {
  background-color: #28a745;
}

.comment-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.comment-box {
  padding: 12px;
  border-radius: 8px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  margin: 0 15px 10px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  position: relative;
}

.comment-user svg {
  min-width: 32px;
}

.comment-meta {
  display: flex;
  align-items: center;
  gap: 12px;
}

.comment-reply-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.comment-reply-btn span {
  font-size: 14px;
  min-width: 16px;  /* Ensures consistent spacing */
}

.comment-reply-btn:hover {
  color: #31C65B;
}

.comment-reply-btn.active {
  color: #31C65B;
}

/* Edit mode styles */
.comment-edit-container {
  width: 100%;
}

.comment-edit-input {
  width: 100%;
  min-height: 60px;
  max-height: 100px !important;
  padding: 12px !important;
  margin-bottom: 10px !important;
  border: 1px solid #31C65B !important;
  border-radius: 8px;
  font-size: 14px;
  color: #000 !important;
  line-height: 1.5;
  resize: vertical;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  transition: border-color 0.2s ease;
  background-color: white;
  font-family: inherit;
}

.comment-edit-input:focus {
  outline: none;
  border-color: #28a745 !important;
}

.edit-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.save-edit-btn,
.cancel-edit-btn {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.save-edit-btn {
  background-color: #31C65B;
  color: white;
}

.save-edit-btn:hover {
  background-color: #28a745;
}

.save-edit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cancel-edit-btn {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  color: #666;
}

.cancel-edit-btn:hover {
  background-color: #e0e0e0;
}

/* Scrollbar styling for the reply input */
.post-comment-textarea::-webkit-scrollbar {
  width: 4px;
}

.post-comment-textarea::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}

.post-comment-textarea::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.post-comment-textarea::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.load-more-comments {
  display: block;
  width: calc(100% - 30px);
  margin: 10px 15px;
  padding: 8px;
  background: none;
  border: none;
  color: #31C65B;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  transition: color 0.2s ease;
}

.load-more-comments:hover {
  color: #28a745;
}

.comments-toggle-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px 15px;
}

.load-more-comments,
.show-less-comments {
  display: block;
  width: calc(100% - 30px);
  padding: 8px;
  background: none;
  border: none;
  color: #31C65B;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  transition: color 0.2s ease;
}

.load-more-comments:hover,
.show-less-comments:hover {
  color: #28a745;
}

.show-less-comments {
  margin-top: -5px; /* Reduce space between buttons */
}

.comments-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  margin-top: 10px;  /* Add space below post buttons */
  margin-bottom: 10px;  /* Add space above comment input */
  border-top: 1px solid #e0e0e0;  /* Add top border */
  border-bottom: none;  /* Remove bottom border */
}

.sort-dropdown {
  position: relative;
  margin-right: 5px;  /* Add some right margin */
}

.sort-select {
  padding: 6px 30px 6px 12px;
  font-size: 14px;
  color: #666;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  min-width: 140px;  /* Ensure consistent width */
}

.sort-select:hover {
  background-color: #f0f0f0;
}

.sort-select:focus {
  outline: none;
  border-color: #31C65B;
  box-shadow: 0 0 0 2px rgba(49, 198, 91, 0.2);
}

/* Optional: Add a label */
.sort-label {
  margin-right: 8px;
  font-size: 14px;
  color: #666;
}

/* Styled scrollbar for comment edit input */
.comment-edit-input::-webkit-scrollbar {
  width: 8px;
}

.comment-edit-input::-webkit-scrollbar-track {
  margin: 4px 0;
  background: transparent;
}

.comment-edit-input::-webkit-scrollbar-thumb {
  background-color: rgba(49, 198, 91, 0.2);
  border: 2px solid #f8f8f8;
  border-radius: 6px;
}

.comment-edit-input::-webkit-scrollbar-thumb:hover {
  background-color: rgba(49, 198, 91, 0.4);
}

/* Add margin below comment actions when reply box is visible */
.comment-actions + .reply-section {
  margin-top: 12px;
}

/* Comment input container and field */
.comment-input-container {
  margin: 0 15px 20px !important;
}

.comment-input-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

/* Comment textarea - updated to match reply textarea */
.post-comment-textarea {
  width: 100%;
  min-height: 50px;
  max-height: 100px !important;
  padding: 12px !important;
  border: 1px solid #31C65B !important;
  border-radius: 8px;
  font-size: 14px;
  color: #000 !important;
  line-height: 1.5;
  resize: vertical;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  transition: border-color 0.2s ease;
  background-color: white;
  font-family: inherit;
}

.post-comment-textarea:focus {
  outline: none;
  border-color: #28a745 !important;
}

/* Updated scrollbar styling to match edit mode */
.post-comment-textarea::-webkit-scrollbar {
  width: 4px;
}

.post-comment-textarea::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}

.post-comment-textarea::-webkit-scrollbar-thumb {
  background: #31C65B;
  border-radius: 4px;
}

.post-comment-textarea::-webkit-scrollbar-thumb:hover {
  background: #28a745;
}

/* Horizontal scrollbar styling */
.post-comment-textarea::-webkit-scrollbar:vertical {
  height: 4px;
}

/* Existing vertical scrollbar styling */
.post-comment-textarea::-webkit-scrollbar {
  width: 4px;
}

.post-comment-textarea::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}

.post-comment-textarea::-webkit-scrollbar-thumb {
  background: #31C65B;
  border-radius: 4px;
}

.post-comment-textarea::-webkit-scrollbar-thumb:hover {
  background: #28a745;
}

/* Comment submit button */
.post-comment-button {
  align-self: flex-start;
  background-color: #31C65B;
  color: white;
  border: none;
  border-radius: 14px;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 5px;
}

.post-comment-button:hover {
  background-color: #28a745;
}

.post-comment-button:disabled {
  background-color: #7b7b7b;
  cursor: not-allowed;
}

/* Share modal styles */
.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-modal-content {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.share-modal h3 {
  margin: 0 0 16px;
  color: #333;
  font-size: 18px;
}

.share-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.share-options button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.share-options button:hover {
  background: #e5e5e5;
}

.close-modal-btn {
  margin-top: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #31C65B;
  color: white;
  cursor: pointer;
  width: 100%;
  transition: background 0.2s ease;
}

.close-modal-btn:hover {
  background: #28a745;
}

.postButtons {
  position: relative;
  z-index: var(--z-index-buttons);
}

.comment-section.modal-open {
  position: relative;
  z-index: var(--z-index-modal-overlay);
}

