@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.dashboard-container {
  padding: 20px !important;
  padding-top: 15px !important;
  width: 70% !important;
  max-width: 1015px !important;
  margin-left: 375px !important;
  margin-top: 90px !important;
  margin-right: 25px !important;
  height: calc(100vh - 90px) !important;
  overflow-y: hidden;
  border-right: none !important;
  border-radius: 8px !important;
  background-color: white;
  box-sizing: border-box !important;
  position: relative;
}

.tabs {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
  padding-bottom: 20px !important;
  margin-left: 10px !important;
  border-bottom: 1px solid rgba(236, 236, 236, 1) !important;
  column-gap: 100px !important;
}

.tab {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 4px !important;
  padding: 10px 20px !important;
  border: none !important;
  background: none !important;
  cursor: pointer !important;
  color: #000000 !important;
  transition: color 0.3s ease !important;
  position: relative !important;
  margin-bottom: 10px !important;
  margin-right: 30px !important;
  font-family: "Inter", sans-serif !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24.2px !important;
}

.tab::after {
  content: '' !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: -5px !important;
  height: 2px !important;
  background-color: #31C65B !important;
  transform: scaleX(0) !important;
  transition: transform 0.3s ease !important;
}

.tab:hover::after {
  transform: scaleX(1) !important;
}

.tab.active::after {
  transform: scaleX(1) !important;
}

.tab.active {
  color: rgb(0, 0, 0) !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 10px 20px !important;
  background-color: transparent !important;
}

.coming-soon-indicator {
  position: absolute !important;
  top: -10px !important;
  right: -70px !important;
  background: #4caf50 !important;
  color: #fff !important;
  padding: 4px 6px !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%) !important;
  white-space: nowrap !important;
  z-index: 1 !important;
}

.saved-posts-container {
  height: 100% !important;
  padding: 20px 0 400px 0 !important;
  margin: 0 !important;
  width: 100% !important;
  overflow-y: auto !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 20px !important;
}
.saved-posts-container .post {
  margin: 0 20px !important;
  width: calc(100% - 00px) !important;
}

/* Responsive breakpoints */
@media screen and (max-width: 1440px) {
  .dashboard-container {
    max-width: 1015px !important;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-container {
    max-width: 775px !important;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-container {
    max-width: 600px !important;
    margin-right: 15px !important;
    margin-left: 365px !important;
  }
}

.underdevelopment-indicator {
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #666 !important;
    text-align: center !important;
    padding: 20px !important;
    max-width: 400px !important;
    line-height: 1.5 !important;
    margin: 20px auto !important; 
    position: relative !important;
    top: 0 !important;
    margin-bottom: 400px !important;
    
}