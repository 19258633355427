.notifications-container {
    padding: 20px !important;
    padding-top: 15px !important;
    width: 70% !important;
    max-width: 1015px !important;
    margin-left: 375px !important;
    margin-top: 90px !important;
    margin-right: 25px !important;
    height: calc(100vh - 90px) !important;
    overflow-y: hidden;
    border-right: none !important;
    border-radius: 8px !important;
    background-color: white;
    box-sizing: border-box !important;
    position: relative;
}

.notifications-title {
    font-family: 'Inter', sans-serif !important;
    font-size: 24px !important;
    color: #000 !important;
    font-weight: 700 !important;
    margin-bottom: 20px !important;
    padding-left: 20px !important;
}

.notifications-list {
    height: calc(100% - 60px) !important;
    overflow-y: auto !important;
    padding: 0 20px !important;
}

.notification-item {
    display: flex !important;
    align-items: flex-start !important;
    padding: 15px !important;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    background-color: #fff !important;
    border: 1px solid #eee !important;
}

.notification-item:hover {
    background-color: #f8f9fa !important;
}

.notification-item.unread {
    background-color: #f0f7ff !important;
    border-left: 3px solid #31C65B !important;
}

.notification-icon {
    width: 24px !important;
    height: 24px !important;
    margin-right: 15px !important;
    margin-top: 3px !important;
}

.notification-icon.follow {
    color: #31C65B !important;
}

.notification-icon.like {
    color: #ff4d4d !important;
}

.notification-icon.comment {
    color: #007bff !important;
}

.notification-icon.reply {
    color: #6c757d !important;
}

.notification-icon.message {
    color: #17a2b8 !important;
}

.notification-content {
    flex: 1 !important;
}

.notification-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-bottom: 5px !important;
}

.actor-name {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 16px !important;
}

.timestamp {
    color: #6c757d !important;
    font-size: 14px !important;
}

.notification-text {
    margin: 0 !important;
    color: #495057 !important;
    font-size: 15px !important;
    line-height: 1.4 !important;
}

.notification-preview {
    margin: 8px 0 0 0 !important;
    padding: 8px !important;
    background-color: #f8f9fa !important;
    border-radius: 4px !important;
    color: #666 !important;
    font-size: 14px !important;
    line-height: 1.4 !important;
}

.loading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 200px !important;
    color: #666 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
}

/* Responsive breakpoints */
@media screen and (max-width: 1440px) {
    .notifications-container {
        max-width: 1015px !important;
    }
}

@media screen and (max-width: 1200px) {
    .notifications-container {
        max-width: 775px !important;
    }
}

@media screen and (max-width: 1024px) {
    .notifications-container {
        max-width: 600px !important;
        margin-right: 15px !important;
        margin-left: 365px !important;
    }
} 