.user-profile-container {
    max-width: 1015px !important;
    width: calc(100% - 400px) !important;
    margin-left: 375px !important;
    margin-top: 90px !important;
    margin-right: 25px !important;
    position: relative !important;
    height: calc(100vh - 90px) !important;
    overflow-y: auto !important;
    scrollbar-color: rgb(100, 236, 100) !important;
}

.user-profile-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
    padding: 20px 0 !important;
}

/* Custom scrollbar styles */
.user-profile-container::-webkit-scrollbar {
    width: 8px !important;
}

.user-profile-container::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 4px !important;
}

.user-profile-container::-webkit-scrollbar-thumb {
    background: #888 !important;
    border-radius: 4px !important;
}

.user-profile-container::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

@media (max-width: 1200px) {
    .user-profile-container {
        width: calc(100% - 300px) !important;
        margin-left: 275px !important;
    }
}

@media (max-width: 768px) {
    .user-profile-container {
        width: calc(100% - 40px) !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
} 