.profile-header {
    position: relative !important;
    width: 100% !important;
    margin-bottom: 24px !important;
}

.cover-photo {
    width: 100% !important;
    height: 300px !important;
    border-radius: 16px !important;
    position: relative !important;
    overflow: hidden !important;
    background-size: cover !important;
    background-position: center !important;
}

.profile-info-overlay {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding: 30px !important;
    background: linear-gradient(to top, rgba(0,0,0,0.7), transparent) !important;
    display: flex !important;
    align-items: flex-end !important;
    gap: 24px !important;
}

.avatar-container {
    margin-bottom: 5px !important;
}

.user-avatar, .user-avatar-icon {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50% !important;
    border: 4px solid white !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    background: white !important;
}

.user-info {
    flex: 1 !important;
    margin-bottom: 18px !important;
}

.user-info h2 {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: white !important;
    margin-bottom: 5px !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3) !important;
}

.profession, .location {
    font-size: 18px !important;
    color: rgba(255,255,255,0.9) !important;
    margin-bottom: 2px !important;
    text-shadow: 0 2px 4px rgba(0,0,0,0.3) !important;
}

.edit-profile-btn {
    background: linear-gradient(135deg, #31C65B, #2196F3) !important;
    color: white !important;
    border: none !important;
    padding: 12px 24px !important;
    border-radius: 30px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    cursor: pointer !important;
    transition: all 0.2s !important;
    margin-bottom: 10px !important;
}

.edit-profile-btn:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 4px 15px rgba(49, 198, 91, 0.3) !important;
} 