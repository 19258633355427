.sidebar {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  background-color: #fff;
  height: calc(100vh - 90px);
  border-right: 1px solid #eee !important;
  position: fixed;
  top: 90px;
  left: 0;
  width: 350px !important;
  box-sizing: border-box;
  overflow: hidden !important;
}

/* User profile container */
.sidebar-profile {
  padding: 0 !important;
  margin-bottom: 10px !important;
}

/* Profile link - default styles */
.profile-link {
  padding: 4px 10px !important;
  border-radius: 50px !important;
  text-decoration: none !important;
  color: inherit !important;
  display: block !important;
  transition: background-color 0.3s ease, color 0.3s ease !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  justify-content: center !important;
  align-items: center !important;
  width: 90% !important;
}

/* Hover effect AND active state - green background and white text */
.profile-link:hover,
.profile-link.active {
  background-color: rgba(49, 198, 91, 1) !important;
}

.profile-link:hover .profile-info h3,
.profile-link:hover .profile-info p,
.profile-link.active .profile-info h3,
.profile-link.active .profile-info p {
  color: white !important;
}

/* Profile content */
.profile-content {
  display: flex !important;
  align-items: center !important;
  padding: 8px 10px !important;
}

 
.profile-avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  background-color: #f5f5f5 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 8px !important;
  border: 1px solid #ffffff !important;
  overflow: hidden !important;
  flex-shrink: 0 !important;
}

/* User's name and profession */
.profile-info {
  flex-grow: 1 !important;
  overflow: hidden !important;
}

/* User's name styling */
.profile-info h3 {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
  color: #000 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.2 !important;
}

/* Profession styling */
.profile-info p {
  margin-left: 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #666 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.2 !important;
}

/* Keep these styles for the disabled appearance */
.sidebarOption.coming-soon {
  cursor: default !important;
  opacity: 0.7;
}

.sidebarOption.coming-soon .custom-icon-class {
  opacity: 0.7;
}

/* Add a container for the main menu items */
.sidebar-menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Logout styles */
.sidebar-logout {
  margin-top: auto;
  padding: 15px 0;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: linear-gradient(to top, #fff 70%, transparent);
}

.sidebarOption.logout {
  position: relative;
  width: calc(100% - 24px);
  margin: 0 12px;
  padding: 12px 18px;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  background: #fff;
  transition: all 0.3s ease;
}

/* Text style */
.sidebarOption.logout h2 {
  color: #ff4757;
  font-weight: 500;
}

/* Icon style */
.sidebarOption.logout .custom-icon-class {
  stroke: #ff4757;
  stroke-width: 1.5;
  transition: all 0.3s ease;
}

/* Hover animations */
.sidebarOption.logout:hover {
  animation: floatButton 3s ease-in-out infinite;
}

.sidebarOption.logout:hover .custom-icon-class {
  animation: floatIcon 3s ease-in-out infinite;
}

@keyframes floatButton {
  0%, 100% { transform: translateY(0) translateX(0); }
  25% { transform: translateY(-4px) translateX(2px); }
  75% { transform: translateY(2px) translateX(-2px); }
}

@keyframes floatIcon {
  0%, 100% { transform: translateY(0) translateX(0); }
  50% { transform: translateY(-4px) translateX(-2px); }
}

.sidebarOption--active {
  background-color: var(--twitter-background);
  border-radius: 30px;
  color: var(--twitter-color);
}

.sidebarOption--active .profile-content {
  background-color: rgba(29, 155, 240, 0.1);
  border-radius: 30px;
}
