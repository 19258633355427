:root {
    --chat-panel-z-index: 1000;
    --primary-color: #007bff;
    --error-color: #dc3545;
    --border-color: #ddd;
    --text-color: #333;
    --text-secondary: #777;
    --bg-hover: #f5f5f5;
}

.chat-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 350px;
    height: calc(100vh - 100px);
    margin-top: 100px;
    background-color: #fff;
    border-left: 1px solid var(--border-color);
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
    z-index: var(--chat-panel-z-index);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    transition: transform 0.3s ease;
}

/* User Panel Styles */
.user-panel {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0.5rem;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
}

.user-panel::-webkit-scrollbar {
    width: 6px;
}

.user-panel::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 3px;
}

.user-panel__header {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    background-color: #f8f9fa;
}

.user-panel__item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.user-panel__item:hover,
.user-panel__item:focus {
    background-color: var(--bg-hover);
    outline: none;
}

.user-panel__item:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: -2px;
}

/* Chat Styles */
.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-header {
    background-color: #f8f9fa;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
}

.chat-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
}

.chat-message {
    margin: 0.5rem 0;
    max-width: 80%;
    word-break: break-word;
}

.chat-message.me {
    margin-left: auto;
    background-color: var(--primary-color);
    color: white;
    border-radius: 15px 15px 0 15px;
    padding: 0.75rem 1rem;
}

.chat-message.other {
    margin-right: auto;
    background-color: #f1f1f1;
    border-radius: 15px 15px 15px 0;
    padding: 0.75rem 1rem;
}

.chat-message.failed {
    opacity: 0.7;
}

.chat-message__timestamp {
    font-size: 0.75rem;
    opacity: 0.7;
    margin-top: 0.25rem;
    display: block;
}

/* Input Styles */
.chat-input {
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
}

.chat-input textarea {
    flex-grow: 1;
    padding: 0.75rem;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    resize: none;
    font-size: 1rem;
    line-height: 1.4;
    min-height: 40px;
    max-height: 200px;
    transition: all 0.2s ease;
}

.chat-input textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.send-button {
    background: var(--primary-color);
    color: white;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.send-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Loading and Error States */
.chat-loading,
.chat-error {
    padding: 1rem;
    text-align: center;
    color: var(--text-secondary);
}

.loading-spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/* Typing Indicator */
.typing-indicator {
    display: flex;
    gap: 4px;
    padding: 0.5rem;
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    background: var(--text-secondary);
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .chat-panel {
        max-width: 100%;
        height: calc(100vh - 60px);
        margin-top: 60px;
    }

    .chat-input textarea {
        font-size: 16px; /* Prevent zoom on iOS */
    }
}

/* Accessibility Focus Styles */
*:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}