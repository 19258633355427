.reply-box {
  padding: 12px;
  margin: 8px 0;
  border-radius: 8px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.reply-user {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.reply-user svg {
  width: 32px;
  height: 32px;
  min-width: 32px;
  color: #666;
}

.reply-meta {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reply-profession {
  margin: 0 !important;
  color: #666;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 1;
}

.reply-timestamp {
  color: #666;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 8px;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 0 !important;
}

.user-info h4 {
  margin: 3px 0 0 0 !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1;
}

.reply-content {
  margin: 8px 0;
  width: 100%;
  box-sizing: border-box;
}

.reply-content p {
  margin: 0;
  font-size: 14px !important;
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
}

.replies-section {
  margin-top: 12px;
  padding-left: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Reply action buttons */
.reply-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0 0;
  margin-top: 5px;
  border-top: 1px solid #e0e0e0;
}

.reply-like-btn,
.reply-share-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.reply-like-btn:hover,
.reply-share-btn:hover {
  color: #31C65B;
}

.reply-like-btn.liked {
  color: #31C65B;
}

/* Options menu buttons */
.reply-options {
  position: relative;
  display: flex;
  align-items: center;
}

.options-toggle {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.options-toggle:hover {
  background-color: rgba(49, 198, 91, 0.1);
  color: #31C65B;
}

.options-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  z-index: 10;
  min-width: 120px;
}

.options-menu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  text-align: left;
}

.options-menu button:hover {
  background-color: rgba(49, 198, 91, 0.1);
  color: #31C65B;
}

/* Edit mode buttons */
.reply-edit-container {
  width: 100%;
}

.reply-edit-input {
  width: 100%;
  min-height: 60px;
  max-height: 100px !important;
  padding: 12px !important;
  margin-bottom: 10px !important;
  border: 1px solid #31C65B !important;
  border-radius: 8px;
  font-size: 14px;
  color: #000 !important;
  line-height: 1.5;
  resize: vertical;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  transition: border-color 0.2s ease;
  background-color: white;
  font-family: inherit;
}

.reply-edit-input:focus {
  outline: none;
  border-color: #28a745 !important;
}

/* Scrollbar styling */
.reply-edit-input::-webkit-scrollbar {
  width: 4px;
}

.edit-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.save-edit-btn,
.cancel-edit-btn {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.save-edit-btn {
  background-color: #31C65B;
  color: white;
}

.save-edit-btn:hover {
  background-color: #28a745;
}

.save-edit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cancel-edit-btn {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  color: #666;
}

.cancel-edit-btn:hover {
  background-color: #e0e0e0;
}

.reply-header-right {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}

.reply-timestamp::before {
  content: none;
}

.reply-timestamp:hover {
  color: #444;
}

.show-more-replies,
.show-less-replies {
  background: none;
  border: none;
  color: #31C65B;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-top: 8px;
  transition: color 0.2s ease;
}

.show-more-replies:hover,
.show-less-replies:hover {
  color: #28a745;
}

.reply-section {
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

.replies-section {
  margin-top: 12px;
  padding-left: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comment-reply-btn.active {
  color: #31C65B;
}

/* Add animation for smooth appearance */
.reply-input, .reply-section {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-modal-content {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.share-modal h3 {
  margin: 0 0 16px;
  color: #333;
  font-size: 18px;
}

.share-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.share-options button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.share-options button:hover {
  background: #e5e5e5;
}

.close-modal-btn {
  margin-top: 16px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #31C65B;
  color: white;
  cursor: pointer;
  width: 100%;
  transition: background 0.2s ease;
}

.close-modal-btn:hover {
  background: #28a745;
}

.reply-input {
  margin: 10px 0;
  padding: 0;
  background: none;
  border: none;
}

.reply-input-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.reply-textarea {
  width: 100%;
  min-height: 60px;
  max-height: 100px !important;
  padding: 12px;
  border: 1px solid #31C65B !important;
  border-radius: 8px;
  font-size: 14px;
  color: #000 !important;
  line-height: 1.5;
  resize: vertical;
  box-sizing: border-box;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
  transition: border-color 0.2s ease;
  background-color: white;
  font-family: inherit;
}

.reply-textarea:focus {
  outline: none;
  border-color: #28a745 !important;
}

/* Scrollbar styling */
.reply-textarea::-webkit-scrollbar {
  width: 4px;
}

.post-reply-button {
  align-self: flex-start;
  background-color: #31C65B;
  color: white;
  border: none;
  border-radius: 14px;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.post-reply-button:hover {
  background-color: #28a745;
}

.post-reply-button:disabled {
  background-color: #7b7b7b;
  cursor: not-allowed;
}
