.feed-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    margin-top: 90px;
    
}

.feed {
    margin-left: 350px; /* Sidebar width */
    padding: 20px;
    padding-top: 180px; /* Header height + PostBox height + spacing */
    min-height: calc(100vh - 90px); /* Full height minus header */
    overflow-y: auto;
}

.feed__posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 650px; /* Match PostBox width */
}

/* Add padding to the top of the first post */
.feed__posts > :first-child {
    margin-top: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed::-webkit-scrollbar {
    display: none;
}

/* Ensure content within feed maintains proper spacing */
.feed > * {
    margin-bottom: 20px;
}

/* Remove margin from last child to prevent extra spacing */
.feed > *:last-child {
    margin-bottom: 0;
}

/* Ensure proper spacing on mobile devices */
@media screen and (max-width: 768px) {
    .feed {
        margin: 0 10px;
        min-width: auto;
        flex: 1;
    }
}

/* Remove the header-border class that was creating the green line */
.header-border {
    display: none;
}