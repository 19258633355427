/* Search Container and Input */
.search-container {
    position: relative;
    width: 342px;
    margin: 0 auto;
    z-index: 1000;
}

.search-form {
    width: 100%;
}

.search-input-wrapper {
    position: relative;
    width: 100%;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    width: 16px;
    height: 16px;
    pointer-events: none;
}

.search-input {
    width: 100%;
    height: 40px;
    padding: 8px 36px;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    font-size: 16px;
    color: #0b0c0c;
    transition: all 0.3s ease;
    background: white;
}

.search-input:focus {
    border-color: #31C65B;
    box-shadow: 0 0 0 2px rgba(49, 198, 91, 0.2);
    outline: none;
}

/* Dropdown and Results */
.search-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 80%;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 12px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1500;
}

.suggestions-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.suggestion-card {
    display: flex;
    padding: 12px;
    border-radius: 8px;
    background: #f8f9fa;
    transition: all 0.3s ease;
    cursor: pointer;
    align-items: center;
    gap: 8px;
}

.suggestion-card:hover {
    background: #f1f3f5;
}

/* Avatar Styles */
.avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;
    background: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* User Info Styles */
.user-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
}

.profession {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #868e96;
    margin: 0;
    line-height: 1.2;
}

.profession svg {
    color: #868e96;
}

/* Loading State */
.loading-indicator {
    text-align: center;
    padding: 20px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

/* Trending Searches */
.trending-searches {
    padding: 8px 0;
}

.trending-searches h3 {
    font-size: 14px;
    color: #868e96;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.trending-item {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.3s ease;
    color: #495057;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.trending-item:hover {
    background: #f1f3f5;
    color: #31C65B;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .search-container {
        max-width: 100%;
    }

    .search-dropdown {
        position: fixed;
        top: 90px;
        left: 0;
        right: 0;
        width: calc(100% - 32px);
        margin: 0 16px;
        max-height: calc(100vh - 100px);
        border-radius: 12px;
    }
}

/* Accessibility Focus Styles */
.search-input:focus-visible {
    outline: 2px solid #31C65B;
    outline-offset: 2px;
}

.trending-item:focus-visible,
.suggestion-card:focus-visible {
    outline: 2px solid #31C65B;
    outline-offset: 2px;
    background: #f1f3f5;
}

.suggestion-card .user-info h4.username {
    font-size: 18px !important;
    font-weight: 600;
    color: #212529;
    margin: 0;
    line-height: 1.2;
} 