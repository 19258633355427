.edit-profile-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 9999 !important;
    overflow: hidden !important;
}

.edit-profile-modal {
    background: white !important;
    border-radius: 16px !important;
    width: 90% !important;
    margin-left: 375px !important;
    max-width: 800px !important;
    max-height: 90vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    position: relative !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
}

.modal-cover-photo {
    width: 100% !important;
    height: 300px !important;
    background-size: cover !important;
    background-position: center !important;
    position: relative !important;
    border-radius: 16px 16px 0 0 !important;
}

.change-cover-btn {
    position: absolute !important;
    bottom: 20px !important;
    right: 20px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    border: none !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    transition: all 0.2s !important;
    color: #1a1a1a !important;
}

.change-cover-btn:hover {
    background: white !important;
    transform: scale(1.05) !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.modal-content {
    padding: 30px !important;
}

.cover-options {
    background: #f8f9fa !important;
    border-radius: 12px !important;
    padding: 24px !important;
    margin-bottom: 24px !important;
}

.cover-options h3 {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1a1a1a !important;
    margin-bottom: 20px !important;
}

.preset-covers {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
    gap: 16px !important;
}

.preset-cover {
    height: 100px !important;
    border-radius: 8px !important;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer !important;
    transition: transform 0.2s !important;
    border: 2px solid transparent !important;
}

.preset-cover:hover {
    transform: scale(1.05) !important;
}

.preset-cover.selected {
    border-color: #31C65B !important;
    box-shadow: 0 0 0 2px rgba(49, 198, 91, 0.3) !important;
}

.form-sections {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
}

.form-section {
    background: #f8f9fa !important;
    padding: 24px !important;
    border-radius: 12px !important;
}

.form-section h3 {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1a1a1a !important;
    margin-bottom: 20px !important;
}

.input-group {
    position: relative !important;
    margin-bottom: 20px !important;
    width: 100% !important;
}

.input-group:last-child {
    margin-bottom: 0 !important;
}

.input-group label {
    display: block !important;
    margin-bottom: 8px !important;
    font-weight: 500 !important;
    color: #4a4a4a !important;
}

.input-group input {
    width: 100% !important;
    padding: 12px !important;
    border: 1px solid #ddd !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    color: #1a1a1a;
    transition: border-color 0.2s !important;
}

.input-group input:focus {
    border-color: #31C65B !important;
    outline: none !important;
}

.vision-input {
    width: 100% !important;
    min-height: 120px !important;
    padding: 12px !important;
    border: 1px solid #ddd !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    color: #1a1a1a;
    resize: vertical !important;
    transition: border-color 0.2s !important;
}

.vision-input:focus {
    border-color: #31C65B !important;
    outline: none !important;
}

.button-container {
    display: flex !important;
    justify-content: space-between !important;
    gap: 16px !important;
    margin-top: 30px !important;
    padding-top: 20px !important;
    border-top: 1px solid #eee !important;
}

.button-container button {
    padding: 12px 24px !important;
    border-radius: 30px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    transition: all 0.2s !important;
    min-width: 120px !important;
}

.button-container .cancel {
    background: none !important;
    border: 1px solid #ddd !important;
    color: #666 !important;
}

.button-container .cancel:hover {
    background: #f8f9fa !important;
    border-color: #666 !important;
}

.button-container .save {
    background: linear-gradient(135deg, #31C65B, #2196F3) !important;
    border: none !important;
    color: white !important;
}

.button-container .save:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 4px 15px rgba(49, 198, 91, 0.3) !important;
}

.button-container button:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
    transform: none !important;
}

@media (max-width: 768px) {
    .edit-profile-modal {
        width: 95% !important;
        margin: 10px !important;
    }

    .modal-content {
        padding: 20px !important;
    }

    .form-section {
        padding: 20px !important;
    }

    .button-container {
        flex-direction: column !important;
    }

    .button-container button {
        width: 100% !important;
    }
}

.country-options {
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 1000;
}

.country-option {
    padding: 10px;
    cursor: pointer;
    color: #1a1a1a;
}

.country-option:hover {
    background-color: #f0f0f0;
}

.country-input-wrapper {
    position: relative;
}

.country-input {
    width: 100%;
    padding: 12px 40px 12px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    color: #1a1a1a;
    transition: border-color 0.2s;
}

.country-input-icons {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.clear-icon, .dropdown-icon {
    cursor: pointer;
    color: #1a1a1a;
    margin-left: 8px;
}

.suggestions {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    background: white !important;
    border: 1px solid #ddd !important;
    border-radius: 8px !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    z-index: 1000 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    color: #1a1a1a;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.skills-list {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 8px !important;
    margin-top: 10px !important;
    width: 100% !important;
}

.skill-item {
    background: #e8f5e9 !important;
    color: #2e7d32 !important;
    padding: 6px 12px !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
    font-size: 14px !important;
}

.remove-skill {
    cursor: pointer !important;
    color: #d32f2f !important;
    transition: color 0.2s !important;
}

.remove-skill:hover {
    color: #b71c1c !important;
} 