.post {
  display: flex;
  align-items: flex-start;
  padding: 15px 20px !important;
  border-radius: 10px;
  margin: 10px 0;
  margin-left: 60px !important;
  background-color: white !important;
  max-width: 600px !important;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #31C65B;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}


.post__avatar {
  margin-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.post__avatar svg {
  width: 51px;
  height: 51px;
  color: #666;
}

.post__body {
  flex: 1;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
}

.post__header {
  display: flex;
  flex-direction: column;
}

.post__headerText {
  margin-bottom: 0;
}

.post__headerText h3 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.post__headerDescription {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.post__headerDescription h4 {
  font-size: 14px;
  color: #777;
  margin-top: 0;
  margin-bottom: 0;
}

.post__headerDescription h4::after {
  content: "•";
  margin: 0 5px;
  color: #777;
}

.post__body p {
  margin: 10px 0;
  font-size: 15px;
  line-height: 1.5;
  color: #444;
  text-align: left;
}

.post__footer {
  margin-top: 5px;
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
}

.postButtons {
  display: flex;
  gap: 15px;
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 5px;
  position: relative;
  z-index: var(--z-index-dropdown);
}

.postButtons button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
}

.postButtons .comment-like-btn.liked {
  color: #31C65B;
}

.postButtons button:hover {
  color: #31C65B;
}

.button-text {
  font-size: 14px;
  color: inherit;
}

.post-share-container {
    display: inline-block;
}

.post-share-options {
    position: fixed;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: var(--z-index-modal);
    min-width: 160px;
    padding: 0;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.post-share-options button {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    cursor: pointer;
    color: #666;
    font-size: 13px;
    text-align: left;
    transition: all 0.2s ease;
}

.post-share-options button:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.post-share-options button:active {
    background-color: rgba(49, 198, 91, 0.1);
}

.post-share-options button:hover {
    background-color: #f5f5f5;
    color: #31C65B;
}

.post-share-options button.copied {
    color: #31C65B;
    background-color: rgba(49, 198, 91, 0.1);
}

.post-share-options button.copied:hover {
    background-color: rgba(49, 198, 91, 0.1);
    color: #31C65B;
}

.post__actionTag {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.post__actionIcon {
    color: #31C65B;
}

.post__actionTag span {
    color: #31C65B;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.post__content {
    margin: 10px 0;
    max-width: 100%;
}

.post__content p {
    font-size: 15px;
    line-height: 1.5;
    color: #444;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
}

.share-error-message {
    color: #dc3545;
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.share-error-message button {
    color: #31C65B;
    border: none;
    background: none;
    padding: 4px 8px;
    cursor: pointer;
}

.comment-bookmark-btn {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.comment-bookmark-btn.saved {
    color: #31C65B;
}

.comment-bookmark-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(49, 198, 91, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.comment-bookmark-btn:active::after {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
}

.post__content a {
    word-break: break-all;
}

/* Add responsive design */
@media screen and (max-width: 768px) {
    .post {
        margin-left: 20px !important;
        padding: 10px !important;
    }

    .post__avatar svg {
        width: 40px;
        height: 40px;
    }

    .post-share-options {
        right: -10px;
    }
}



/* Simplify word wrapping */
.post__content {
    overflow-wrap: break-word;
    max-width: 100%;
}

/* Add proper transitions */
.postButtons button {
    transition: color 0.3s ease, background-color 0.3s ease;
}

/* Organize z-indices */
:root {
    --z-index-base: 1;
    --z-index-comment: 100;
    --z-index-dropdown: 1000;
    --z-index-modal: 2000;
    --z-index-overlay: 3000;
}

/* Ensure comment section doesn't create a new stacking context */
.comment-section {
    position: relative;
    z-index: var(--z-index-comment);
}

.post-share-options {
    z-index: var(--z-index-dropdown);
}



.comment-share-btn.sharing {
    opacity: 0.7;
    cursor: not-allowed;
}

.comment-share-btn.error {
    color: #dc3545;
}

.comment-share-btn:disabled {
    cursor: not-allowed;
}

/* Add overlay when modal is open */
.post-share-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: var(--z-index-overlay);
    display: none;
}

.post-share-container.active .post-share-overlay {
    display: block;
}

/* Update scrolling behavior */
.feed {
    position: relative;
    height: calc(100vh - 90px);
    overflow-y: auto;
    padding-bottom: 40px;
}

