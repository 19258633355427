/* Root variables for consistent measurements */
:root {
  --sidebar-width: 350px;
  --gap: 25px;
  --header-height: 90px;
  --main-content-width: 1015px;
  --postbox-width: 650px;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1440px) {
  :root {
    --main-content-width: 1015px;
  }
  
  .user-profile {
    max-width: var(--main-content-width) !important;
  }

  .postBox {
    max-width: var(--postbox-width) !important;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --main-content-width: 775px;
  }
  
  .user-profile {
    max-width: var(--main-content-width) !important;
  }

  .postBox {
    max-width: var(--postbox-width) !important;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --main-content-width: 600px;
    --gap: 15px;
  }
  
  .user-profile {
    max-width: var(--main-content-width) !important;
    margin-right: var(--gap) !important;
    margin-left: calc(var(--sidebar-width) + var(--gap)) !important;
  }

  .postBox {
    max-width: var(--postbox-width) !important;
  }
}

/* Add more breakpoints as needed */
/* Add this class to create the continuous border */
.header-border {
  position: fixed;
  top: 202px;  /* This accounts for the header height (90px) + some padding */
  left: 0;
  width: calc(350px + var(--post-box-width)); /* Sidebar width + PostBox width */
  height: 1px;
  background-color: var(--theChange-color);
  z-index: 11;  /* Above the PostBox z-index (10) */
}

