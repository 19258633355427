/* Main Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #31C65B;
  margin-bottom: 80px;
  height: 90px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-header);
}

/* Left Section */
.header__left {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 30px;
}

.header__left h2 {
  margin-right: 80px;
  color: #FFFFFF;
  font-size: 30px;
  font-family: "Josefin Sans", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

/* Center Section */
.header__center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header__searchInput {
  width: 342px !important;
  height: 40px !important;
  font-size: 16px !important;
  color: #000000 !important;
  padding: 12px 20px !important;
  border: 1px solid #ccc !important;
  border-radius: 25px !important;
  outline: none !important;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease !important;
  background-color: #F9F9F9 !important;
  margin-left: 200px !important;
}

.header__searchInput:focus {
  border-color: #888 !important;
  box-shadow: 0 0 8px rgba(136, 136, 136, 0.5) !important;
  background-color: #ffffff !important;
}

.header__searchInput::placeholder {
  color: #999 !important;
  transition: color 0.3s ease !important;
}

.header__searchInput:focus::placeholder {
  color: #666 !important ;
}

/* Right Section */
.header__right {
  display: flex !important;
  gap: 100px !important;
  align-items: center !important;
  margin-right: 20px !important;

}

/* Icon Base Styles */
.header__icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  margin: 0 5px !important;
  color: black !important;
  text-decoration: none !important;
}

.header__icon svg {
  width: 28px !important;
  height: 28px !important;
}

.header__icon:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.header__icon.active {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* Add new class for search positioning */
.header__search-wrapper {
  width: 342px;
  position: relative;
  margin: 0;
  z-index: 1500;
}

