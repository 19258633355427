.vision_section {
    background: white !important;
    border-radius: 16px !important;
    padding: 30px !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
    margin: 24px 0 !important;
    max-width: 900px;
    align-self: center;
}

.vision_section h3 {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1a1a1a !important;
    margin-bottom: 16px !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
}

.vision_section p {
    font-size: 16px !important;
    line-height: 1.6 !important;
    color: #111111 !important;
    white-space: pre-wrap !important;
} 