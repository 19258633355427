@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.sidebarOption {
    display: flex;
    align-items: center;
    padding: 8px 18px !important;
    cursor: pointer !important;
    gap: 12px !important;
    width: calc(100% - 24px) !important;
    text-decoration: none !important;
    box-sizing: border-box !important;
    transition: all 0.3s ease !important;
    border-bottom: 1px solid #cecbcb;
    
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;
}

.text-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.custom-icon-class {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    transition: all 0.3s ease;
}

.sidebarOption > h2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #000000;
    text-align: left;
    margin: 0;
    white-space: nowrap;
    transition: all 0.3s ease;
}

.sidebarOption.disabled {
    cursor: default;
    opacity: 0.7;
}

.sidebarOption.disabled h2 {
    font-size: 18px;
    color: #666;
    font-style: italic;
}

.sidebarOption.files:hover h2 {
    color: #FF9800;
}

.sidebarOption.files.sidebarOption--active h2 {
    color: #FF9800;
}

.sidebarOption.logout,
.sidebar-logout {
    border: none !important;
}

.coming-soon {
    position: relative;
    cursor: default !important;
    opacity: 0.7;
}

.sidebarOption.coming-soon:hover h2 {
    color: #666 !important;
}

.sidebarOption .sidebarOption {
    border-bottom: none;
}

.sidebarOption.logout {
    border-bottom: none !important;
}